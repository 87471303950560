
/* TODO: this is more easily responsive than current implementation of chat component */
#fake-body.full-height {
  height: 100svh;
  background: url('~@/assets/backgrounds/wallpaper-zwijsen2.png') no-repeat;
  background-size: cover;
  .container {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  #chat-window {
    height: 90svh;
    width: 100%;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.1);

    &::v-deep .container {
      max-height: unset !important;
      min-height: unset !important;
      margin-top: 100px;
      height: calc(100% - 100px);
      //max-height: calc(100% - 90px);
    }

    &::v-deep #chat {
      overflow-y: scroll;
      max-height: 100% !important;
      width: 100%;
    }
  }
}
